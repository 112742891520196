import React, { useEffect } from "react";
import { useParams } from "@reach/router";

import { jsWindowRedirectReplace } from "../../utils/routeHelpers";

import * as Analytics from "../../utils/analytics";

interface ExternalRedirectorProps {
  path: string;
  redirects?: {
    slug: string;
    url: string;
  }[];
}

const ExternalRedirector = (props: ExternalRedirectorProps) => {
  const params = useParams();

  useEffect(() => {
    const redirectIndex = props.redirects.findIndex(
      redirect => redirect.slug === params.key
    );

    if (redirectIndex > -1) {
      Analytics.track("Redirected to External Site", { site: params.key });
      setTimeout(() => {
        jsWindowRedirectReplace(props.redirects[redirectIndex].url);
      }, 700);
    } else {
      jsWindowRedirectReplace("/");
    }
  }, []);

  return <div />;
};

export default ExternalRedirector;

import React from "react";
import { graphql } from "gatsby";
import { Router } from "@reach/router";
import withClientRouteLoading from "../hoc/withClientRouteLoading/withClientRouteLoading";
import ExternalRedirector from "../components/ExternalRedirector/ExternalRedirector";
import { useUserContext } from "../contexts/User/User.context";
import { createClientSideLocaleBasePath } from "../utils/helpers";

interface RedirectProps {
  data: {
    alamedaapi: {
      redirects: {
        slug: string;
        url: string;
      }[];
    };
  };
}

function Redirect({
  data: {
    alamedaapi: { redirects },
  },
}: RedirectProps) {
  const {
    state: {
      user: {
        locale: { code },
      },
    },
  } = useUserContext();

  const basepath = createClientSideLocaleBasePath(code, "/r");
  return (
    <Router basepath={basepath}>
      <ExternalRedirector redirects={redirects} path="/:key" />
    </Router>
  );
}

export default withClientRouteLoading(Redirect);

export const redirectsQuery = graphql`
  query REDIRECTS_QUERY {
    alamedaapi {
      redirects {
        slug
        url
      }
    }
  }
`;
